import { NavigateNext } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LogoLoading from "../../components/LogoLoading";
import { getTransfers } from "../../store/transfer/transferSlice";
import { debug, printSize, readableDate } from "../../utils";

const List = ({ direction = "sent", setCurrentDirection }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const info = useSelector((state) => state.info);
  const transferList = useSelector((state) => state.transfer[direction].list);
  const [transfers, setTransfers] = useState([]);

  useEffect(() => {
    setCurrentDirection(direction);
    if (!transferList) {
      dispatch(getTransfers(direction));
    }
    debug("transferList", transferList);
    setTransfers(transferList || []);
  }, [transferList, dispatch, direction, setCurrentDirection]);

  return (
    <div className="my-6 py-3">
      {info.loading?.getTransfers ? (
        <LogoLoading />
      ) : (
        <div>
          {transfers.length === 0 ? (
            <div className="my-10 text-center text-xl load-animation">
              {direction === "sent"
                ? t("All the transfers you send will appear here")
                : t("Keep track of transfers")}
            </div>
          ) : (
            transfers.map((item, index) => (
              <Link key={index} to={"/transfers/" + direction + "/" + item.id}>
                <div className="p-3 mb-1 border rounded flex justify-between hover:bg-gray-100">
                  <div className="grid content-center">
                    <div className="text-lg">{item.title}</div>
                    <div className="text-gray-400">
                      <small className="pr-1 after:content-['·'] after:ml-1">
                        {item.downloads === 0
                          ? t("Not yet downloaded")
                          : t("Downloaded")}
                      </small>
                      <small className="pr-1 after:content-['·'] after:ml-1">
                        {item.files.length === 1
                          ? t("1 file")
                          : t("{{count}} files", { count: item.files.length })}
                      </small>
                      <small className="pr-1 after:content-['·'] after:ml-1">
                        {printSize(item.size)}
                      </small>
                      <small className="pr-1 ">
                        {readableDate(item.created_at)}
                      </small>
                    </div>
                  </div>
                  <div className="grid content-center">
                    <NavigateNext fontSize="large" />
                  </div>
                </div>
              </Link>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default List;
