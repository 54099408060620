import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { edit } from "../../store/auth/authSlice";

const SecurityTab = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const info = useSelector((state) => state.info);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

  const validation = yup.object({
    password: yup
      .string()
      .required(t("{{field}} is required", { field: t("Password") })),
    repeat_password: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { repeat_password: "", password: "" },
    resolver: yupResolver(validation),
  });

  const onSubmit = (data) => {
    dispatch(edit({ id: user.id, password: data.password }));
  };

  return (
    <Box>
      <h3 className="text-4xl mb-8">{t("Password and security")}</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="mb-6">
          <div className="mb-4">
            <label>{t("Password")}</label>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  className="mt-2"
                  error={typeof errors.password !== "undefined"}
                  size="small"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  placeholder={t("Enter your new password")}
                  helperText={errors.password && t(errors.password.message)}
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          onMouseDown={(e) => {
                            e.preventDefault();
                          }}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className="mb-4">
            <label>{t("Repeat Password")}</label>
            <Controller
              name="repeat_password"
              control={control}
              render={({ field }) => (
                <TextField
                  className="mt-2"
                  error={typeof errors.repeat_password !== "undefined"}
                  size="small"
                  type={showPasswordRepeat ? "text" : "password"}
                  fullWidth
                  placeholder={t("Repeat your new password")}
                  helperText={
                    errors.repeat_password && t(errors.repeat_password.message)
                  }
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setShowPasswordRepeat(!showPasswordRepeat);
                          }}
                          onMouseDown={(e) => {
                            e.preventDefault();
                          }}
                          edge="end"
                        >
                          {showPasswordRepeat ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </div>
        </Box>
        <Box className="flex justify-end">
          <Button
            type="submit"
            variant="contained"
            className="rounded px-6"
            disabled={info.loading?.edit}
            startIcon={info.loading?.edit && <CircularProgress size={"1rem"} />}
          >
            {t("Save Changes")}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
export default SecurityTab;
