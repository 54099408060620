import { ArrowBack, Email } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InfoMessage from "../../components/InfoMessage";
import { forgetPassword } from "../../store/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../layout";
import Header from "../../layout/Header";
import { useEffect } from "react";
import { resetInfo } from "../../store/info/infoSlice";

const ForgetPassword = () => {
  const info = useSelector((state) => state.info);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validation = yup.object({
    email: yup
      .string()
      .email(t("{{field}} Email is not a valid email", { field: "" }))
      .required(t("{{field}} Email is required", { field: "" })),
  });

  useEffect(() => {
    dispatch(resetInfo());
  }, [dispatch]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { email: "" },
    resolver: yupResolver(validation),
  });

  const onSubmit = (data) => {
    dispatch(forgetPassword({ ...data, navigate }));
  };

  return (
    <Layout header={<Header />} className={"fullscreen"} noUploader>
      <div className="flex" style={{ height: "calc(100vh - 100px)" }}>
        <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          style={{ height: 500, margin: "auto" }}
        >
          <Grid md={5} xs={12} item>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                textAlign: "center",
              }}
            >
              <div className="flex justify-center items-center">
                <IconButton
                  onClick={() => {
                    dispatch(resetInfo());
                    navigate("/login");
                  }}
                >
                  <ArrowBack />
                </IconButton>
                <h3 className="text-xl">{t("Reset your password")}</h3>
              </div>
              <p>
                {t(
                  "We will send you the necessary information to reset your password."
                )}
              </p>
            </Box>
            <div style={{ padding: 24 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box className="p-3">
                  <InfoMessage />
                  <div className="mb-4">
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          error={errors.email && true}
                          size="small"
                          fullWidth
                          placeholder={t("Email")}
                          helperText={errors.email && errors.email.message}
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Email />
                              </InputAdornment>
                            ),
                          }}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-4">
                    <Button
                      type="submit"
                      variant="contained"
                      className="rounded-full w-full"
                      disabled={info.loading?.forgetPassword}
                      startIcon={
                        info.loading?.forgetPassword && (
                          <CircularProgress size={"1rem"} />
                        )
                      }
                    >
                      {t("Send email")}
                    </Button>
                  </div>
                  {info.message === 'Email is not registered' && <div className="mb-4">
                    <a href="/register">
                      <Button
                        variant="contained"
                        className="rounded-full w-full"

                      >
                        {t("Register")}
                      </Button>
                    </a>
                  </div>}
                </Box>
              </form>
            </div>
          </Grid>
          <Grid md={7} xs={12} item>
            <Box className="auth-background h-full hidden lg:block"></Box>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};
export default ForgetPassword;
