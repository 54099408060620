import { FileDownloadOutlined } from "@mui/icons-material";
import { Button, Grid, Tooltip } from "@mui/material";
import { useEffect } from "react";
import { defaultStyles, FileIcon } from "react-file-icon";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LogoLoading from "../../components/LogoLoading";
import Layout from "../../layout";
import Header from "../../layout/Header";
import { onSidebarCloseNavigate } from "../../store/info/infoSlice";
import { sidebarOpen } from "../../store/sidebar/sidebarSlice";
import { startDownload } from "../../store/transfer/transferSlice";
import { setStep } from "../../store/uploader/uploaderSlice";
import { printSize, readableDate } from "../../utils";

const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, token } = useParams();
  const transfer = useSelector((state) => state.transfer.item);
  const info = useSelector((state) => state.info);

  useEffect(() => {
    dispatch(sidebarOpen());
    dispatch(onSidebarCloseNavigate(`/downloads/${id}/${token}`));
    dispatch(setStep("download"));
  }, [dispatch, id, token]);

  return (
    <Layout
      header={<Header />}
      sidebarContent
      className={"fullscreen home-image"}
      bgImg={true}

    >
      <div className="p-5 relative h-full">
        {info.loading?.previewTransfer ? (
          <LogoLoading />
        ) : (
          <div>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className="pb-5"
            >
              <Grid
                item
                lg={12}
                md={12}
                className="flex justify-between items-center"
              >
                <div>
                  <h3 className="text-4xl">{t("Items in this transfer")}</h3>
                  <div className="my-1">
                    <div className="text-gray-400">
                      <small className="pr-1 after:content-['·'] after:ml-1">
                        {transfer?.files?.length === 1
                          ? t("1 file")
                          : t("{{count}} files", {
                              count: transfer?.files?.length,
                            })}
                      </small>
                      <small className="pr-1">
                        {t("Sending date")}:{" "}
                        {readableDate(transfer?.created_at)}
                      </small>
                    </div>
                  </div>
                </div>
                <div>
                  {transfer?.expire?.status === false && (
                    <Button
                      type="button"
                      variant="contained"
                      className="rounded-full px-6"
                      onClick={() => {
                        dispatch(
                          startDownload({
                            id,
                            type: "entire",
                            token,
                          })
                        );
                      }}
                    >
                      {t("Download all")}
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
            <hr />
            <Grid container alignItems="stretch" spacing={3} className="py-5">
              {transfer?.files?.map((file) => (
                <Grid key={file?.id} item lg={4} md={6}>
                  <div
                    className="border p-3 h-full"
                    style={{ flex: "0 0 100%" }}
                  >
                    <div
                      className="flex flex-col justify-between"
                      style={{ height: "100%" }}
                    >
                      <div></div>
                      <div className="flex justify-center">
                        {file?.preview &&
                          file?.type.split("/")[0] === "image" && (
                            <img src={file?.preview} alt={file?.name} />
                          )}
                        {file?.preview &&
                          file?.type.split("/")[0] === "video" && (
                            <video controls>
                              <source src={file?.preview} type={file?.type} />
                            </video>
                          )}
                        {!file?.preview && (
                          <div style={{ width: 50 }}>
                            <FileIcon
                              extension={file.type.split("/")[1]}
                              {...defaultStyles[file.type.split("/")[1]]}
                            />
                          </div>
                        )}
                      </div>
                      <div className="flex justify-between items-center">
                        <div>
                          {file?.name.length > 15 ? (
                            <Tooltip title={file?.name} placement="bottom">
                              <p className="font-light">
                                {file?.name.slice(0, 15) + "..."}
                              </p>
                            </Tooltip>
                          ) : (
                            <p className="font-light">{file?.name}</p>
                          )}
                          <div className="text-gray-400 font-thin text-left">
                            <small className="pr-1">
                              {printSize(file?.size)}
                            </small>
                          </div>
                        </div>

                        {transfer?.expire?.status === false && (
                          <button
                            className="logo-blue float-right"
                            onClick={() => {
                              dispatch(
                                startDownload({
                                  id,
                                  file_id: file?.id,
                                  type: "single",
                                  token,
                                })
                              );
                            }}
                          >
                            <div>
                              <FileDownloadOutlined />
                            </div>
                            <div className="text-sm">{t("Download")}</div>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default List;
