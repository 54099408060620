import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { onSidebarCloseNavigate } from "../../store/info/infoSlice";
import { sidebarOpen } from "../../store/sidebar/sidebarSlice";
import { setStep } from "../../store/uploader/uploaderSlice";

const Base = ({ children, shouldAuth = false }) => {
  const user = JSON.parse(localStorage.getItem("authUser"));
  const step = useSelector((state) => state.uploader.step);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  let location = useLocation();

  useEffect(() => {
    if (
      location.pathname.split("/")[1] !== "downloads" &&
      step === "download"
    ) {
      dispatch(setStep("form"));
    }
    if (isLoggedIn && shouldAuth) {
      dispatch(onSidebarCloseNavigate("/"));
      dispatch(sidebarOpen());
    }
  }, [isLoggedIn, dispatch, shouldAuth, location, step]);

  if (!isLoggedIn && !user && shouldAuth) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default Base;
