import { ArrowLeft, Check } from "@mui/icons-material";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import verify from "../../assets/images/verify.png";
import { setAction, setStep } from "../../store/uploader/uploaderSlice";

const Verify = ({ code, setCode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sender = useSelector((state) => state.uploader.sender);
  const info = useSelector((state) => state.info);

  return (
    <div className="text-center">
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
          width: "100%",
          height: "170px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={verify} alt="" style={{ width: 130 }} />
      </Box>
      <div className="m-auto my-2" style={{ width: 300 }}>
        <p className="text-2xl">{t("Confirm your email")}</p>
        <p className="my-2">
          {t(
            "We've sent a verification code to {{email}} to make sure you're really you. (remember to check your Spam folder)",
            { email: sender }
          )}
        </p>
      </div>
      <Box className="p-3">
        <div className="mb-4">
          <TextField
            fullWidth
            size="small"
            placeholder={t("Code")}
            error={!!info.message && !info.loading?.verifyUploads}
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />
        </div>
      </Box>
      <hr />
      <Box className="p-6 flex justify-between">
        <Button
          type="button"
          variant="outlined"
          className="rounded-full"
          style={{ minWidth: 0, padding: 5 }}
          onClick={() => {
            dispatch(setStep("form"));
          }}
        >
          <ArrowLeft />
        </Button>
        <Button
          type="button"
          variant="contained"
          className="rounded-full px-6"
          disabled={info.loading?.verifyUploads || code.length < 6}
          onClick={() => {
            dispatch(setAction("verify"));
          }}
          startIcon={
            info.loading?.verifyUploads ? (
              <CircularProgress size={"1rem"} />
            ) : (
              <Check />
            )
          }
        >
          {t("Verify")}
        </Button>
        <div></div>
      </Box>
    </div>
  );
};

export default Verify;
