import { ThemeProvider } from "@mui/system";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Base from "./components/Base";
import LogoLoading from "./components/LogoLoading";
import theme from "./configs/theme";
import Account from "./pages/Account";
import ForgetPassword from "./pages/Auth/ForgetPasswordForm";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import Contacts from "./pages/Contacts";
import Downloads from "./pages/Downloads";
import DownloadsList from "./pages/Downloads/List";
import Help from "./pages/Help";
import Home from "./pages/Home";
import ResetPassword from "./pages/Auth/ResetPassword";
import Transfers from "./pages/Transfers";
import TransfersSingle from "./pages/Transfers/Single";
import { checkAuth } from "./store/auth/authSlice";
import { debug } from "./utils";
import VerifyEmail from "./pages/VerifyEmail";
import PrivacyAndCookies from "./pages/Contracts/PrivacyAndCookies";
import TermsOfService from "./pages/Contracts/TermsOfService";


function App() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.info.loading);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("authUser"));
    const other = JSON.parse(localStorage.getItem("other"));
    if (!!user && other?.type === "session") {
      debug("user", user);
      dispatch(checkAuth());
    }
  }, [dispatch]);

  if (loading?.checkAuth) {
    return <LogoLoading />;
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Base>
                <Home />
              </Base>
            }
          />
          <Route
            path="/help"
            element={
              <Base>
                <Help />
              </Base>
            }
          />
          <Route
            path="/register"
            element={
              <Base>
                <Register />
              </Base>
            }
          />
          <Route
            path="/login"
            element={
              <Base>
                <Login />
              </Base>
            }
          />
          <Route
            path="/forget-password"
            element={
              <Base>
                <ForgetPassword />
              </Base>
            }
          />
          <Route
            path="/reset-password/:token"
            element={
              <Base>
                <ResetPassword />
              </Base>
            }
          />
          <Route
            path="/downloads/:id/:token"
            element={
              <Base>
                <Downloads />
              </Base>
            }
          />
          <Route
            path="/verify/:token"
            element={
              <Base>
                <VerifyEmail />
              </Base>
            }
          />
          <Route
            path="/downloads/:id/:token/preview"
            element={
              <Base>
                <DownloadsList />
              </Base>
            }
          />
          <Route
            path="/contacts"
            element={
              <Base shouldAuth>
                <Contacts />
              </Base>
            }
          />
          <Route
            path="/transfers"
            element={
              <Base shouldAuth>
                <Transfers />
              </Base>
            }
          />
          <Route
            path="/transfers/:direction/:id"
            element={
              <Base>
                <TransfersSingle />
              </Base>
            }
          />
          <Route
            path="/contracts/privacy-and-cookies"
            element={
              <Base>
                <PrivacyAndCookies />
              </Base>
            }
          />
           <Route
            path="/contracts/terms-of-service"
            element={
              <Base>
                <TermsOfService />
              </Base>
            }
          />
          <Route
            path="/account"
            element={
              <Base shouldAuth>
                <Account />
              </Base>
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
