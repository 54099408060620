import { Close } from "@mui/icons-material";
import { Grid, IconButton, Paper } from "@mui/material";
import axiosV1 from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import axios from "../../configs/axios";
import {
  resetInfo,
  resetLoading,
  setLoading,
} from "../../store/info/infoSlice";
import {
  cancelAction,
  clearRecipient,
  setAction,
  setStep,
} from "../../store/uploader/uploaderSlice";
import { animateProgress, debug } from "../../utils";
import Done from "./Done";
import Download from "./Download";
import Error from "./Error";
import Progress from "./Progress";
import UploadForm from "./UploadForm";
import Verify from "./Verify";
import VerifyEmail from "./VerifyEmail";

var intervalID;
var abortController;
var uploadedSize = 0;
var prevUploadedSize = {};
var globalFiles = [];
var currentFile = {};
var uploadedFiles = {};
var totalSize = 0.0;
var globalData = {};
var transferId = "";

const Uploader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const forward = useSelector((state) => state.uploader.forward);
  const step = useSelector((state) => state.uploader.step);
  const action = useSelector((state) => state.uploader.action);
  const transfer = useSelector((state) => state.transfer.item);
  const isDragOver = useSelector((state) => state.info.isDragOver);

  const [isLocalDragOver, setIsLocalDragOver] = useState(false);
  const [data, setData] = useState(globalData);
  const [code, setCode] = useState("");
  const [files, setFiles] = useState(globalFiles);
  const [status, setStatus] = useState("Transferring");

  const handleFileUpload = (event) => {
    event.preventDefault();
    const eventFiles = [...event.target.files];
    const tempFiles = [
      ...files,
      ...eventFiles.map((item) => ({ file: item, uuid: uuidv4() })),
    ];
    globalFiles = [...tempFiles];
    setFiles(globalFiles);
  };

  useEffect(() => {
    switch (action) {
      case "new":
        globalFiles = [];
        currentFile = {};
        uploadedFiles = {};
        setFiles(globalFiles);
        setStatus("Transferring");
        dispatch(clearRecipient());
        dispatch(setStep("form"));
        break;

      case "cancel":
        dispatch(setLoading("deleteChunks"));
        dispatch(cancelAction());
        abortController && abortController.abort();
        debug("abort", abortController.signal);
        debug("clearInterval", intervalID);
        clearInterval(intervalID);
        axios
          .delete(`/uploads/${transferId}`)
          .catch((err) => {
            debug("deleteUploadsError", err);
            dispatch(setStep("error"));
          })
          .finally(() => {
            dispatch(resetLoading("deleteChunks"));
          });
        break;

      case "verify":
        dispatch(setLoading("verifyUploads"));
        axios
          .put(`/uploads/${transferId}/verify`, {
            code,
          })
          .then((res) => {
            if (res.status === 200) {
              if (files.length > 0) {
                currentFile = files[0];
                const reader = new FileReader();
                reader.onload = (event) => uploadFile(event);
                reader.readAsArrayBuffer(currentFile.file);
                dispatch(setStep("progress"));
              }
            }
          })
          .catch((err) => {
            debug("verifyUploadsError", err);
          })
          .finally(() => {
            dispatch(resetLoading("verifyUploads"));
          });
        break;

      case "complete":
        setStatus("Processing");
        dispatch(setLoading("completeUploads"));
        axios
          .put(`/uploads/${transferId}/complete`, {})
          .then((res) => {
            if (res.status === 200) {
              transferId = res.data?.id;
              dispatch(setStep("done"));
              navigate(`/transfers/sent/${transferId}`);
            }
          })
          .catch((err) => {
            debug("completeUploadsError", err);
            dispatch(setStep("error"));
          })
          .finally(() => {
            dispatch(resetLoading("completeUploads"));
          });
        break;

      case "forward":
        dispatch(setLoading("forwardUploads"));
        const dataObj = {
          title: data.title || transfer?.files[0].name,
          message: data.message || "",
          recipients: data.recipients,
        };
        axios
          .post(`/uploads/${transfer.id}/forward`, dataObj)
          .then((res) => {
            if (res.status === 201) {
              dispatch(setStep("done"));
              navigate(`/transfers/sent/${res.data?.id}`);
            }
          })
          .catch((err) => {
            debug("forwardUploadsError", err);
            dispatch(setStep("error"));
          })
          .finally(() => {
            dispatch(resetLoading("forwardUploads"));
          });
        break;

      default:
        break;
    }

    dispatch(setAction("send"));
  }, [action]); //eslint-disable-line

  const onSubmit = (data) => {
    debug("data", data);
    dispatch(resetInfo());
    setStatus("Transferring");
    totalSize = files.reduce((total, x) => total + x.file.size, 0);
    prevUploadedSize = {};
    uploadedSize = 0;
    setCode("");
    if (forward) {
      dispatch(setAction("forward"));
    } else {
      dispatch(setLoading("beginUploads"));
      const dataObj = {
        files: files?.map((item) => ({
          id: item.uuid,
          name: item.file.name,
          size: item.file.size,
          type: item.file.type,
        })),
        email: data.sender,
        size: totalSize,
        title: data.title || files[0]?.file?.name,
        message: data.message || "",
        recipients: data.recipients,
      };
      axios
        .post(`/uploads/email`, dataObj)
        .then((res) => {
          transferId = res.data?.id;
          res.status === 202 && dispatch(setStep("verify"));

          if (res.status === 201) {
            if (files.length > 0) {
              currentFile = files[0];
              const reader = new FileReader();
              reader.onload = (event) => uploadFile(event);
              reader.readAsArrayBuffer(currentFile.file);
              dispatch(setStep("progress"));
            }
          }
        })
        .catch((err) => {
          debug("beginUploadsError", err);
          dispatch(setStep("error"));
        })
        .finally(() => {
          dispatch(resetLoading("beginUploads"));
        });
    }
    globalData = data;
    setData(globalData);
  };

  const uploadChunk = async (chunk, chunkSize, chunkId) => {
    const uuid = currentFile.uuid;
    const url = await axios
      .post(
        `/uploads/${transferId}/files/${uuid}/part`,
        {
          chunk_size: chunkSize,
          chunk_number: chunkId + 1,
        },
        {
          signal: abortController.signal,
        }
      )
      .then((res) => {
        if (res.status === 201) {
          return res.data.url;
        }
      })
      .catch((err) => debug("partError", err));

    await axiosV1
      .put(url, chunk, {
        signal: abortController.signal,
        onUploadProgress: (progressEvent) => {
          const diff = progressEvent.loaded - (prevUploadedSize[chunkId] || 0);
          debug("uplodedByte", diff);
          uploadedSize = uploadedSize + diff;
          const progress = Math.round((100 * uploadedSize) / totalSize);
          const uploaded = (
            (progress * totalSize) /
            (100 * 1024 * 1024)
          ).toFixed(1);
          animateProgress(progress, uploaded);
          prevUploadedSize[chunkId] = progressEvent.loaded;
        },
      })
      .catch((err) => {
        debug("uploadAwsError", err);
        err?.code !== "ERR_CANCELED" && dispatch(setStep("error"));
      });
  };

  const uploadFile = async (event) => {
    if ((action === "send" || action === "verify") && !!event) {
      const file = currentFile.file;
      const uuid = currentFile.uuid;
      const data = event.target.result;
      const size = data.byteLength;

      await axios
        .post(`/uploads/${transferId}/files/${uuid}/begin`, {
          file_name: file.name,
          file_size: size,
          file_type: file.type,
        })
        .then((res) => {
          if (res.status === 201) {
            const chunks = [];
            const chunkSize = parseInt(res.data?.chunk_size);
            const fileSize = parseInt(res.data?.file_size);

            const totalChunks = Math.ceil(fileSize / chunkSize);
            for (let chunkId = 1; chunkId <= totalChunks; chunkId++) {
              let tempChunkSize = chunkSize;
              if (chunkSize * chunkId > fileSize) {
                tempChunkSize = fileSize - chunkSize * (chunkId - 1);
              }
              chunks.push(tempChunkSize);
            }

            let i = 0;
            let multipleRequestCount = 0;
            let chunk;
            abortController = new AbortController();
            intervalID = setInterval(async () => {
              if (multipleRequestCount < 3 && chunks.length !== i) {
                multipleRequestCount++;
                chunk = chunks[i];
                const slicedData = data.slice(
                  i * chunkSize,
                  i * chunkSize + chunk
                );
                uploadChunk(slicedData, chunk, i).then(() => {
                  multipleRequestCount--;
                });
                i++;
              }
              if (multipleRequestCount === 0) {
                debug("clearInterval", intervalID);
                clearInterval(intervalID);
                await axios
                  .put(`/uploads/${transferId}/files/${uuid}/end`, {
                    chunk_count: Math.ceil(size / chunkSize),
                  })
                  .then((res) => {
                    if (res.status === 200) {
                      prevUploadedSize = {};
                      uploadedFiles[currentFile.uuid] = "end";
                      if (
                        Object.values(uploadedFiles).length !== files.length
                      ) {
                        currentFile =
                          files[Object.values(uploadedFiles).length];
                        const reader = new FileReader();
                        reader.onload = (event) => uploadFile(event);
                        reader.readAsArrayBuffer(currentFile.file);
                      } else {
                        dispatch(setAction("complete"));
                      }
                    }
                  })
                  .catch((err) => {
                    debug("endUploadsError", err);
                    dispatch(setStep("error"));
                  });
              }
            }, 500);
          }
        })
        .catch((err) => {
          debug("beginResponseError", err);
          dispatch(setStep("error"));
        });
    }
  };

  return (
    <Grid
      container
      justifyContent="left"
      className={isDragOver ? "grid-area items-start md:items-center": "grid-area items-start md:items-center"}
      sx={{ height: "calc(100vh - 91px)" }}
      onDragEnter={(event) => {
        event.preventDefault();
        setIsLocalDragOver(true);
        console.log('11')
      }}
      onDragOver={(event) => {
        event.preventDefault();
        setIsLocalDragOver(true);
        console.log('22')

      }}
      onDragLeave={(event) => {
        event.preventDefault();
        setIsLocalDragOver(false);
        console.log('33')

      }}
      onDrop={(event) => {
        event.preventDefault();
        if (isDragOver && isLocalDragOver) {
          const tempFiles = [...event.dataTransfer.files];
          setFiles([
            ...files,
            ...tempFiles.map((item) => ({ file: item, uuid: uuidv4() })),
          ]);
        }
        setIsLocalDragOver(false);
      }}
    >
      <Paper
        elevation={isDragOver ? 24 : 3}
        
        className="rounded-t-3xl rounded-b-none md:rounded-xl md:ml-[10%] w-[100%] md:w-[380px]"
       
      >
        {step === "form" && (
          <>
            {files?.length > 0 && (
              <div className="flex justify-end p-3">

                <button onClick={() => {
                  setFiles([])
                }} className="">Tümünü Sil</button>
              </div>
            )}
            <div className="max-h-40 overflow-auto">

              {files?.map((item, i) => (
                <div
                  key={i}
                  className="border-b pl-3 flex justify-between items-center"
                >
                  <p className="text-lg">
                    {item.file.name?.length > 30
                      ? item.file.name?.slice(0, 30) + "..."
                      : item.file.name}
                  </p>

                  <IconButton
                    onClick={() => {
                      const tempArray = [...files];
                      tempArray.splice(i, 1);
                      globalFiles = [...tempArray];
                      setFiles(globalFiles);
                    }}
                  >
                    <Close size="small" />
                  </IconButton>
                </div>
              ))}
              {forward &&
                transfer?.files?.map((item, i) => (
                  <div
                    key={i}
                    className="border-b pl-3 flex justify-between items-center"
                  >
                    <p className="text-lg">{item.name}</p>
                  </div>
                ))}
            </div>
              <UploadForm
                onSubmit={onSubmit}
                handleFileUpload={handleFileUpload}
                files={files}
              />
          </>
        )}
        {step === "progress" && (
          <Progress totalSize={totalSize} status={status} />
        )}
        {step === "done" && <Done />}
        {step === "download" && <Download />}
        {step === "verifyEmail" && <VerifyEmail />}
        {step === "verify" && <Verify setCode={setCode} code={code} />}
        {step === "error" && <Error />}
      </Paper>
    </Grid>
  );
};

export default Uploader;
