import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fileUpload: "",
  directoryUpload: "",
  sender: "",
  recipient: "",
  recipients: [],
  title: "",
  message: "",
  forward: false,
  step: "form",
  action: "send",
};

export const uploaderSlice = createSlice({
  name: "uploader",
  initialState,
  reducers: {
    setForward: (state, actions) => {
      state.forward = actions.payload;
    },
    setAction: (state, actions) => {
      state.action = actions.payload;
    },
    setSender: (state, actions) => {
      state.sender = actions.payload;
    },
    setTitle: (state, actions) => {
      state.title = actions.payload;
    },
    setMessage: (state, actions) => {
      state.message = actions.payload;
    },
    setStep: (state, actions) => {
      if (state.step !== "progress") {
        state.step = actions.payload;
      } else {
        if (actions.payload !== "form") {
          state.step = actions.payload;
        }
      }
    },
    cancelAction: (state, actions) => {
      state.step = "form";
    },
    addRecipient: (state, actions) => {
      const temp = [...state.recipients];
      const index = temp.findIndex((item) => item === actions.payload);
      if (index && actions.payload !== "") {
        temp.push(actions.payload);
      }
      state.recipients = temp;
    },
    removeRecipient: (state, actions) => {
      const temp = [...state.recipients];
      const index = temp.findIndex((item) => item === actions.payload);
      temp.splice(index, 1);
      state.recipients = temp;
    },
    clearRecipient: (state, actions) => {
      state.recipients = [];
    },
  },
  extraReducers: {},
});

export const {
  setSender,
  setStep,
  setAction,
  setForward,
  addRecipient,
  removeRecipient,
  cancelAction,
  clearRecipient,
  setMessage,
  setTitle
} = uploaderSlice.actions;
export default uploaderSlice.reducer;
