
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from "react-i18next";

const ConfirmDialog = ({ open = false, handleClose = () => { }, agree = () => { } }) => {

  const {t} = useTranslation();


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("Delete")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("If you continue the process, the selected item will be deleted.")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("Disagree")}</Button>
        <Button onClick={() => {
          agree();
          handleClose();
        }} autoFocus>
          {t("Agree")}

        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default ConfirmDialog;
