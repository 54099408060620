import axios1 from "axios";
import { store } from "./store";
import { setError } from "../store/info/infoSlice";
import { checkAuth } from "../store/auth/authSlice";
import { sidebarClose } from "../store/sidebar/sidebarSlice";

const axios = axios1.create({
  baseURL: window.runConfig.apiUrl,
});

axios.interceptors.request.use(
  (request) => {
    const accessToken = JSON.parse(localStorage.getItem("accessToken"));
    if (accessToken) {
      request.headers.common["Authorization"] = "Bearer " + accessToken;
    }
    return request;
  },
  (error) => {
    return error.request;
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(checkAuth());
      store.dispatch(sidebarClose());
    }
    store.dispatch(
      setError({
        message: error.response.data?.message,
        code: error.response.status,
      })
    );
    return error.response;
  }
);

export default axios;
