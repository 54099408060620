import { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import TabPanel from "./TabPanel";
import { Grid } from "@mui/material";

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const VerticalTabs = ({
  tabs,
  right = false,
  currentIndes = 0,
  variant = "standard",
  children,
}) => {
  const [value, setValue] = useState(currentIndes);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={4}>
        {right ? (
          <>
            <Grid item xs={8}>
              {tabs.map((step, index) => {
                if (value !== index) {
                  return null;
                }
                return (
                  <TabPanel
                    value={value}
                    key={step.label || "tab" + index}
                    index={index}
                  >
                    {step.component}
                  </TabPanel>
                );
              })}
            </Grid>
            <Grid item xs={4}>
              <Tabs
                orientation="vertical"
                TabIndicatorProps={{ sx: { left: 0, right: "unset" } }}
                value={value}
                onChange={handleChange}
                aria-label="Tabs"
                variant={variant}
              >
                {tabs.map((step, index) => {
                  return (
                    <Tab
                      className="text-gray-600 border-gray-600 items-start"
                      icon={step.icon || null}
                      label={<p className="capitalize">{step.label}</p> || null}
                      key={step.label || "tab" + index}
                      {...a11yProps(index)}
                    />
                  );
                })}
              </Tabs>
              {children}
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={4}>
              <Tabs
                orientation="vertical"
                value={value}
                onChange={handleChange}
                aria-label="Tabs"
                variant={variant}
              >
                {tabs.map((step, index) => {
                  return (
                    <Tab
                      className="text-gray-600 border-gray-600 items-end"
                      icon={step.icon || null}
                      label={<p className="capitalize">{step.label}</p> || null}
                      key={step.label || "tab" + index}
                      {...a11yProps(index)}
                    />
                  );
                })}
              </Tabs>
              {children}
            </Grid>
            <Grid item xs={8}>
              {tabs.map((step, index) => {
                if (value !== index) {
                  return null;
                }
                return (
                  <TabPanel
                    value={value}
                    key={step.label || "tab" + index}
                    index={index}
                  >
                    {step.component}
                  </TabPanel>
                );
              })}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default VerticalTabs;
