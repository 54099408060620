import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import error from "../../assets/images/error.png";
import InfoMessage from "../InfoMessage";

const Error = () => {
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
          width: "100%",
          height: "300px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={error} alt="" style={{ width: 130 }} />
      </Box>
      <div className="m-auto" style={{ width: 300 }}>
        <InfoMessage />
      </div>
      <hr />
      <Box className="p-6 flex justify-center">
        <Button
          type="button"
          variant="contained"
          className="rounded-full px-6"
          onClick={() => window.location.reload()}
        >
          {t("Try again!")}
        </Button>
      </Box>
    </div>
  );
};

export default Error;
