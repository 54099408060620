import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sidebarClose } from "../../store/sidebar/sidebarSlice";

const Logo = ({ black,handleOpenForm=()=>{} }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateTo = (to) => {
    dispatch(sidebarClose());
    setTimeout(() => {
      navigate(to);
    }, 500);
  };

  return (
    <div className="flex">
      <button
        type="button"
        onClick={() => {
          navigateTo("/");
        }}
      >
        <img
          style={{ height: 45 }}
          className="w-auto"
          src={black ? "/logo_black.png" : "/logo.png"}
          alt="Uzman Transfer"
        />
      </button>
      <button
        type="button"
        className="h-[23px]"
        onClick={() => {
          handleOpenForm()
        }}
      >
        <div className={!black ? "bg-white ml-4 rounded-md flex justify-center p-1 text-[10px] h-[23px]":"bg-black text-white ml-2 rounded-md flex justify-center p-1 text-[10px] h-[23px]"}>BETA</div>
      </button>
      <img className="rounded-md h-[23px] ml-3" src="/turkey-flag.png"></img>
   
    </div>
  );
};

export default Logo;
