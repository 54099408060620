import { CheckCircle } from "@mui/icons-material";
import { Box, Paper } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

const RegisteredScreen = ({ email = "" }) => {
  const { t } = useTranslation();


  return (
    <div className="flex" style={{ padding: 24 }}>
      <Paper elevation={3} style={{ width: 350, margin: "auto" }}>
        <Box className="text-center p-12">
          <div className="mb-4">
            <CheckCircle style={{ fontSize: "4rem" }} color="primary" />
          </div>
          <h2 className="text-4xl mb-4">{t("Verify Your Email")}</h2>
          <p>
            <Trans
              i18nKey={
                "<bold>{{email}}</bold> a verification link has been sent to the address. (If you cannot find the mail in your inbox, please check your spam or spam folder.)"
              }
              values={{ email: email }}
              components={{ bold: <b /> }}
            >
              <bold>{{ email }}</bold> a verification link has been sent to the
              address. (If you cannot find the mail in your inbox, please check
              your spam or spam folder.)
            </Trans>
          </p>
        </Box>
      </Paper>
    </div>
  );
};
export default RegisteredScreen;
