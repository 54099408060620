import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setAction } from "../../store/uploader/uploaderSlice";

const Progress = ({ totalSize, status }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [validate, setValidate] = useState(false);

  useEffect(() => {
    document.querySelector("#progress-bar").style.transform = "none";
  }, []);

  return (
    <div className="text-center">
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
          width: "100%",
          height: "300px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress
          className="absolute z-0"
          color="gray"
          size={200}
          variant="determinate"
          value={100}
        />
        <CircularProgress
          id="progress-bar"
          className="absolute z-10 spin"
          size={200}
          variant="determinate"
          value={3}
          sx={{
            transform: "none",
          }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            id="progress-text"
            variant="h4"
            component="div"
            color="text.secondary"
          >
            {`0%`}
          </Typography>
        </Box>
      </Box>
      <div className="m-auto" style={{ width: 300, height: 100 }}>
        {validate && <p className="text-2xl">{t("Cancel this transfer?")}</p>}
        <div className={validate ? "hidden" : ""}>
          <p className="text-2xl">{t(status)}...</p>
          <p className="text-lg">
            <span id="progress-mb">0.0</span>
            {t("{{size}} uploded", {
              size: " / " + (totalSize / (1024 * 1024)).toFixed(1) + " MB",
            })}
          </p>
        </div>
      </div>
      <hr />
      <Box className="p-6 flex justify-center">
        {validate ? (
          <div className="flex justify-between">
            <Button
              type="button"
              variant="outlined"
              className="rounded-full px-6 mx-2"
              onClick={() => setValidate(false)}
            >
              {t("No")}
            </Button>
            <Button
              type="button"
              variant="contained"
              className="rounded-full px-6 mx-2"
              onClick={() => dispatch(setAction("cancel"))}
            >
              {t("Yes")}
            </Button>
          </div>
        ) : (
          <Button
            type="button"
            variant="contained"
            className="rounded-full px-6"
            onClick={() => setValidate(true)}
          >
            {t("Cancel")}
          </Button>
        )}
      </Box>
    </div>
  );
};

export default Progress;
