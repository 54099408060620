import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout";
import { checkAuth, resetPassword } from "../../store/auth/authSlice";
import InfoMessage from "../../components/InfoMessage";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { resetInfo } from "../../store/info/infoSlice";

const ResetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const info = useSelector((state) => state.info);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

  const validation = yup.object({
    password: yup
      .string()
      .required(t("{{field}} is required", { field: t("Password") })),
    repeat_password: yup
      .string()
      .required(t("{{field}} is required", { field: t("Password") }))
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { password: "" },
    resolver: yupResolver(validation),
  });

  useEffect(() => {
    dispatch(checkAuth(token));
  }, [token, dispatch]);

  const onSubmit = (data) => {
    dispatch(resetPassword({ ...data, token }));
  };

  return (
    <Layout className={"fullscreen home-image"} noUploader>
      <Grid
        container
        justifyContent="center"
        alignItems="stretch"
        className="h-full"
      >
        <div
          className="flex items-center justify-center"
          style={{ padding: 24, height: "100vh" }}
        >
          <Paper elevation={3} className="m-auto" style={{ width: 350 }}>
            {info.type === "info" ? (
              <Box className="p-3">
                <InfoMessage />
                <div className="flex justify-between mb-4">
                  <Button
                    type="submit"
                    variant="contained"
                    className="rounded-full"
                    onClick={() => {
                      dispatch(resetInfo());
                      navigate("/");
                    }}
                  >
                    {t("Home")}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    className="rounded-full"
                    onClick={() => {
                      dispatch(resetInfo());
                      navigate("/login");
                    }}
                  >
                    {t("Login")}
                  </Button>
                </div>
              </Box>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box className="p-3">
                  <InfoMessage />
                  <div className="mb-4">
                    <h3>{t("Reset your password")}</h3>
                  </div>

                  <div className="mb-4">
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          error={errors.password && true}
                          size="small"
                          type={showPassword ? "text" : "password"}
                          fullWidth
                          placeholder={t("Password")}
                          helperText={
                            errors.password && errors.password.message
                          }
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  style={{ padding: 11 }}
                                  onClick={() => {
                                    setShowPassword(!showPassword);
                                  }}
                                  onMouseDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-4">
                    <Controller
                      name="repeat_password"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          className="mt-2"
                          error={typeof errors.repeat_password !== "undefined"}
                          size="small"
                          type={showPasswordRepeat ? "text" : "password"}
                          fullWidth
                          placeholder={t("Repeat your new password")}
                          helperText={
                            errors.repeat_password && t(errors.repeat_password.message)
                          }
                          {...field}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => {
                                    setShowPasswordRepeat(!showPasswordRepeat);
                                  }}
                                  onMouseDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  edge="end"
                                >
                                  {showPasswordRepeat ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-4">
                    <Button
                      type="submit"
                      variant="contained"
                      className="rounded-full w-full"
                      disabled={info.loading?.resetPassword}
                      startIcon={
                        info.loading?.resetPassword && (
                          <CircularProgress size={"1rem"} />
                        )
                      }
                    >
                      {t("Submit")}
                    </Button>
                  </div>
                </Box>
              </form>
            )}
          </Paper>
        </div>
      </Grid>
    </Layout>
  );
};

export default ResetPassword;
