import {

  Grid,
} from "@mui/material";

import Layout from "../../layout";
import Header from "../../layout/Header";
import { useEffect, useState } from "react";
import axios from "../../configs/axios";

const PrivacyAndCookies = () => {
  const [contract, setContract] = useState(null);

  async function getContractData() {
    const response = await axios.get(process.env.REACT_APP_CMS_API_URL + '/pages/show/?type=kvkk')
    setContract(response?.data?.description)
  }
  useEffect(() => {

    getContractData()
  }, [])


  return (
    <Layout
      header={<Header whiteBg={true} />}
      className={"fullscreen"}
      noUploader
    >
      <div className="flex" style={{ height: "calc(100vh - 100px)" }}>
        <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          style={{ height: 500, margin: "auto" }}
        >
          <Grid md={5} xs={12} item>

            <div style={{ padding: 24 }}>
              <span dangerouslySetInnerHTML={{__html: contract}} />

            </div>
          </Grid>

        </Grid>
      </div>
    </Layout>
  );
};
export default PrivacyAndCookies;
