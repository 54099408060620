import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  class: '',
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    sidebarOpen: (state) => {
      state.class = 'sidebar-open';
    },
    sidebarClose: (state) => {
      state.class = '';
    }
  },
  extraReducers: {}
});

export const { sidebarOpen, sidebarClose } = sidebarSlice.actions;
export default sidebarSlice.reducer;