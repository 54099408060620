import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: '#4076bc',
    },
    gray: {
      main: '#f5f5f5',
    }
  },
});
export default theme;