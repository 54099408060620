import { Send } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import done from "../../assets/images/success.png";
import { setAction, setForward } from "../../store/uploader/uploaderSlice";

const Done = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="text-center">
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
          width: "100%",
          height: "300px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={done} alt="" style={{ width: 250 }} />
      </Box>
      <div className="m-auto my-2" style={{ width: 300, height: 100 }}>
        <p className="text-2xl">{t("You're done!")}</p>
        <p className="my-2">
          {t(
            "The transfer email has been sent. Your transfer is available for 7 days."
          )}
        </p>
      </div>
      <hr />
      <Box className="p-6 flex justify-center">
        <Button
          type="button"
          variant="contained"
          className="rounded-full px-6"
          onClick={() => {
            dispatch(setForward(false));
            dispatch(setAction("new"));
          }}
          startIcon={<Send />}
        >
          {t("Send another?")}
        </Button>
      </Box>
    </div>
  );
};

export default Done;
