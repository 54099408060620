import { Email, Person, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { register, resetSession } from "../../store/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import InfoMessage from "../../components/InfoMessage";
import RegisteredScreen from "./RegisteredScreen";
import { useEffect, useState } from "react";
import Layout from "../../layout";
import Header from "../../layout/Header";
import { resetInfo } from "../../store/info/infoSlice";

const Register = () => {
  const registered = useSelector((state) => state.auth.registered);
  const info = useSelector((state) => state.info);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const validation = yup.object({
    email: yup
      .string()
      .email(t("{{field}} Email is not a valid email", { field: "" }))
      .required(t("{{field}} Email is required", { field: "" })),
    password: yup
      .string()
      .required(t("{{field}} is required", { field: t("Password") })),
    first_name: yup
      .string()
      .required(t("{{field}} is required", { field: t("First Name") })),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { email: "", password: "", first_name: "", last_name: "" },
    resolver: yupResolver(validation),
  });

  const onSubmit = (data) => {
    dispatch(resetInfo());
    dispatch(resetSession("registered"));
    dispatch(register({ ...data }));
  };

  useEffect(() => {
    dispatch(resetSession("registered"));
    dispatch(resetInfo());
  }, [dispatch]);

  return (
    <Layout
      header={<Header whiteBg={true} />}
      className={"fullscreen"}
      noUploader
    >
      <div className="flex" style={{ height: "calc(100vh - 100px)" }}>
        <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          style={{ height: 500, margin: "auto" }}
        >
          <Grid md={5} xs={12} item>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={1} variant="fullWidth">
                <Tab
                  onClick={() => {
                    navigate("/login");
                  }}
                  className="text-gray-600 border-gray-600"
                  label={t("Login")}
                />
                <Tab
                  className="text-gray-600 border-gray-600"
                  label={t("Register")}
                />
              </Tabs>
            </Box>
            <div style={{ padding: 24 }}>
              {registered !== "" && info.message === "" ? (
                <RegisteredScreen email={registered} />
              ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box className="p-3">
                    <InfoMessage />
                    <div className="mb-4">
                      <Controller
                        name="first_name"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            error={errors.first_name && true}
                            size="small"
                            fullWidth
                            placeholder={t("First Name")}
                            helperText={
                              errors.first_name && errors.first_name.message
                            }
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Person />
                                </InputAdornment>
                              ),
                            }}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-4">
                      <Controller
                        name="last_name"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            error={errors.last_name && true}
                            size="small"
                            fullWidth
                            placeholder={t("Last Name")}
                            helperText={
                              errors.last_name && errors.last_name.message
                            }
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Person />
                                </InputAdornment>
                              ),
                            }}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-4">
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            error={errors.email && true}
                            size="small"
                            fullWidth
                            placeholder={t("Email")}
                            helperText={errors.email && errors.email.message}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Email />
                                </InputAdornment>
                              ),
                            }}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-4">
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            error={errors.password && true}
                            size="small"
                            type={showPassword ? "text" : "password"}
                            fullWidth
                            placeholder={t("Password")}
                            helperText={
                              errors.password && errors.password.message
                            }
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    style={{ padding: 11 }}
                                    onClick={() => {
                                      setShowPassword(!showPassword);
                                    }}
                                    onMouseDown={(e) => {
                                      e.preventDefault();
                                    }}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-4 text-left">
                      <p className="w-100 mx-2">
                        <Trans
                          i18nKey={
                            "When you create an account, you agree to our <1>Terms of Service and Privacy and Cookie Notice</1>."
                          }
                        >
                          When you create an account, you agree to our
                          <Link
                            to="/contracts/privacy-and-cookies"
                            className="underline logo-blue cursor-pointer"
                          >
                            Terms of Service and Privacy and Cookie Notice
                          </Link>
                          .
                        </Trans>
                      </p>
                    </div>
                    <div className="mb-4">
                      <Button
                        type="submit"
                        variant="contained"
                        className="rounded-full w-full"
                        disabled={info.loading?.register}
                        startIcon={
                          info.loading?.register && (
                            <CircularProgress size={"1rem"} />
                          )
                        }
                      >
                        {t("Register")}
                      </Button>
                    </div>
                  </Box>
                </form>
              )}
            </div>
          </Grid>
          <Grid md={7} xs={12} item>
            <Box className="auth-background h-full hidden lg:block"></Box>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};
export default Register;
