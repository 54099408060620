import { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import TabPanel from "./TabPanel";

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const HorizontalTabs = ({
  tabs,
  center = false,
  currentIndes = 0,
  variant = "standard",
}) => {
  const [value, setValue] = useState(currentIndes);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Tabs"
          centered={center}
          variant={variant}
        >
          {tabs.map((step, index) => {
            return (
              <Tab
                className="text-gray-600 border-gray-600"
                icon={step.icon || null}
                label={<p className="capitalize">{step.label}</p> || null}
                key={step.label || "tab" + index}
                {...a11yProps(index)}
              />
            );
          })}
        </Tabs>
      </Box>
      {tabs.map((step, index) => {
        if (value !== index) {
          return null;
        }
        return (
          <TabPanel
            value={value}
            key={step.label || "tab" + index}
            index={index}
          >
            {step.component}
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default HorizontalTabs;
