import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "../store/auth/authSlice";
import transferReducer from "../store/transfer/transferSlice";
import contactReducer from "../store/contact/contactSlice";
import sidebarReducer from "../store/sidebar/sidebarSlice";
import infoReducer from "../store/info/infoSlice";
import uploaderReducer from "../store/uploader/uploaderSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  contact: contactReducer,
  transfer: transferReducer,
  uploader: uploaderReducer,
  sidebar: sidebarReducer,
  info: infoReducer,
})

export const store = configureStore({
  reducer: rootReducer,
});
