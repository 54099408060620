import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Layout from "../../layout";
import Header from "../../layout/Header";
import { sidebarClose } from "../../store/sidebar/sidebarSlice";

const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {

      setTimeout(() => {
        const homeImage = document.querySelector('.home-image');
        homeImage.classList.add('image-2')

      }, 10000);
  }, [])
  useEffect(() => {
    dispatch(sidebarClose());
  }, [dispatch])

  return (
    <Layout bgImg={true} header={<Header />} className={"fullscreen home-image"}>
      <></>
    </Layout>
  );
};
export default Home;
