export const readableDate = (t) => {
  return new Date(t * 1000).toLocaleString("tr", {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};

export const convertToBase64 = (e, width = 80, height = 80) => {
  return new Promise(async (resolve, reject) => {
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.onload = (e) => {
      let img = document.createElement("img");
      img.onload = () => {
        let imgWidth = img.width;
        let imgHeight = img.height;
        let startWidth = 0;
        let startHeight = 0;
        let square = width;
        if (imgWidth > imgHeight) {
          if (imgWidth > width) {
            square = imgHeight;
            startWidth = Math.floor((imgWidth - imgHeight) / 2);
          }
        } else {
          if (imgHeight > height) {
            square = imgWidth;
            startHeight = Math.floor((imgHeight - imgWidth) / 2);
          }
        }
        let canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(
          img,
          startWidth,
          startHeight,
          square,
          square,
          0,
          0,
          width,
          height
        );
        resolve(canvas.toDataURL());
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  });
};

export const debug = (name, data) => {
  if (
    process.env.NODE_ENV !== "production" ||
    localStorage.getItem("debug") === "true"
  ) {
    console.log(name, data);
  }
};

export const animateProgress = (progress, uploaded) => {
  const prog = progress < 3 ? 3 : progress;
  document.querySelector("#progress-bar svg circle").style.strokeDashoffset =
    127 - (prog * 127) / 100;
  document.querySelector("#progress-text").innerHTML = prog + "%";
  document.querySelector("#progress-mb").innerHTML = uploaded;
};

export const printSize = (size) => {
  const kb = 1024;
  const mb = 1024 * kb;
  const gb = 1024 * mb;
  if (size > gb) {
    return Math.round(size / gb, 1) + " GB";
  } else if (size > mb) {
    return Math.round(size / mb, 1) + " MB";
  } else {
    return Math.round(size / kb, 1) + " KB";
  }
};

export const downloadFile = (url, filename) => {
  if (typeof url === "string") {
    var tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = url;
    tempLink.setAttribute("download", filename);
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    }, 200);
  }
};
