const LogoLoading = () => {
  return (
    <div className="absolute inset-0">
      <div className="flex h-full justify-center items-center">
        <img
          style={{
            height: 58,
          }}
          className="w-auto m-auto logo-animation"
          src="/logo.png"
          alt="Dosya Transferi"
        />
      </div>
    </div>
  );
};

export default LogoLoading;
