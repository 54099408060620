import { Alert } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { resetInfo } from "../../store/info/infoSlice";
import { debug } from "../../utils";

const InfoMessage = () => {
  const dispatch = useDispatch();
  const info = useSelector((state) => state.info);
  const { t } = useTranslation();
  let infoMessage;

  useEffect(() => {
    const intervalID = setInterval(() => {
      if (info.type === "success") {
        clearInterval(intervalID);
        dispatch(resetInfo("success"));
      }
    }, 6000);
  }, [info, dispatch]);

  if (!info.message && !info.type) {
    return null;
  }

  if (typeof info.message !== "string") {
    infoMessage = t("There was a unexpected error.");
  } else {
    debug("info", info);
    infoMessage = t(info.message);
  }

  return (
    <Alert
      severity={info.type}
      className="mb-4 w-full z-50"
      onClose={() => {
        dispatch(resetInfo("reset"));
      }}
    >
      {infoMessage}
    </Alert>
  );
};

export default InfoMessage;
