import { createSlice } from "@reduxjs/toolkit";

export const infoSlice = createSlice({
  name: "info",
  initialState: {
    loading: {},
    type: "", //warning, error, info, success
    message: "",
    code: "",
    timestamp: 0,
    navigate: "/",
    isDragOver: false,
  },
  reducers: {
    setWarning: (state, action) => {
      state.type = "warning";
      state.message = action.payload?.message || action.payload;
      state.code = action.payload?.code;
      state.timestamp = 0;
    },
    setInfo: (state, action) => {
      state.type = "info";
      state.message = action.payload?.message || action.payload;
      state.code = action.payload?.code;
      state.timestamp = 0;
    },
    setSuccess: (state, action) => {
      state.type = "success";
      state.message = action.payload?.message || action.payload;
      state.code = action.payload?.code;
      state.timestamp = Date.now();
    },
    setError: (state, action) => {
      state.type = "error";
      state.message = action.payload?.message || action.payload;
      state.code = action.payload?.code;
      state.timestamp = 0;
    },
    setLoading: (state, action) => {
      state.loading[action.payload] = true;
    },
    onSidebarCloseNavigate: (state, action) => {
      state.navigate = action.payload;
    },
    resetInfo: (state, action) => {
      if (Date.now() - state.timestamp > 5000) {
        state.type = "";
        state.message = "";
        state.code = "";
        state.timestamp = 0;
      }
    },
    resetLoading: (state, action) => {
      if (!!action.payload) {
        delete state.loading[action.payload];
      } else {
        state.loading = {};
      }
    },
    setIsDragOver: (state, action) => {
      state.isDragOver = action.payload || false;
    },
  },
  extraReducers: {},
});

export const {
  setError,
  setWarning,
  setInfo,
  setSuccess,
  setLoading,
  resetInfo,
  resetLoading,
  onSidebarCloseNavigate,
  setIsDragOver,
} = infoSlice.actions;
export default infoSlice.reducer;
