import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { convertToBase64 } from "../../utils";
import { edit } from "../../store/auth/authSlice";

const InfoTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const info = useSelector((state) => state.info);
  const [avatar, setAvatar] = useState(user.avatar || "");

  const validation = yup.object({
    phone: yup
      .string()
      .matches(
        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
        t("Please enter a valid phone number")
      ),
    email: yup
      .string()
      .email(t("{{field}} Email is not a valid email", { field: "" }))
      .required(t("{{field}} Email is required", { field: "" })),
  });
  const {
    handleSubmit,
    control,
    formState,
    reset,
  } = useForm({
    defaultValues: user,
    resolver: yupResolver(validation),
  });


  useEffect(() => {
    reset(user);
    setAvatar(user.avatar);
  }, [user, reset]);

  const onSubmit = (data) => {
    const obj = {
      avatar,
      id: user.id,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
    };
    dispatch(edit(obj));
  };

  const loadImage = async (e) => {
    const url = await convertToBase64(e);
    setAvatar(url);
  };

  return (
    <Box>
      <h3 className="text-4xl mb-8">{t("Profile Informations")}</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="mb-8 flex justify-start">
          <div>
            <Avatar sx={{ width: 80, height: 80 }} src={avatar} />
          </div>
          <div className="grid content-center text-center">
            <label>
              <p className="rounded px-8 m-1 mx-3 cursor-pointer border border-logo-blue logo-blue">
                {t("Upload Avatar")}
              </p>
              <Controller
                name="fileUpload"
                control={control}
                render={({ field }) => (
                  <input
                    id="fileUpload"
                    type="file"
                    hidden
                    {...field}
                    onChange={(e) => {
                      loadImage(e);
                    }}
                  />
                )}
              />
            </label>
            <label>
              <p
                onClick={() => {
                  setAvatar("");
                }}
                className="rounded px-8 m-1 mx-3 cursor-pointer border border-logo-blue logo-blue"
              >
                {t("Delete Avatar")}
              </p>
            </label>
          </div>
        </Box>
        <Box className="mb-6">
          <div className="mb-4">
            <Controller
              name="first_name"
              control={control}
              render={({ field }) => (
                <TextField
                  error={typeof formState.errors.first_name !== "undefined"}
                  size="small"
                  fullWidth
                  placeholder={t("First Name")}
                  helperText={formState.errors.first_name && t(formState.errors.first_name.message)}
                  {...field}
                />
              )}
            />
          </div>
          <div className="mb-4">
            <Controller
              name="last_name"
              control={control}
              render={({ field }) => (
                <TextField
                  error={typeof formState.errors.last_name !== "undefined"}
                  size="small"
                  fullWidth
                  placeholder={t("Last Name")}
                  helperText={formState.errors.last_name && t(formState.errors.last_name.message)}
                  {...field}
                />
              )}
            />
          </div>
          <div className="mb-4">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  error={typeof formState.errors.email !== "undefined"}
                  size="small"
                  fullWidth
                  placeholder={t("Email")}
                  helperText={formState.errors.email && t(formState.errors.email.message)}
                  {...field}
                />
              )}
            />
          </div>
          <div className="mb-4">
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <TextField
                  error={typeof formState.errors.phone !== "undefined"}
                  size="small"
                  fullWidth
                  placeholder={t("Phone")}
                  helperText={formState.errors.phone && t(formState.errors.phone.message)}
                  {...field}
                />
              )}
            />
          </div>
        </Box>
        <Box className="flex justify-end">
          <Button
            type="submit"
            variant="contained"
            className="rounded px-6"
            disabled={JSON.stringify(formState?.dirtyFields) === '{}'}
            startIcon={info.loading?.edit && <CircularProgress size={"1rem"} />}
          >
            {t("Save Changes")}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
export default InfoTab;
