import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Layout from "../../layout";
import Header from "../../layout/Header";
import { sidebarClose } from "../../store/sidebar/sidebarSlice";
import { setStep } from "../../store/uploader/uploaderSlice";

const VerifyEmail = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sidebarClose());
    dispatch(setStep("verifyEmail"));
  }, [dispatch]);

  return (
    <Layout header={<Header />} className={"fullscreen home-image"}>
      <></>
    </Layout>
  );
};
export default VerifyEmail;
