import Grid from "@mui/material/Grid";
import { Box, Container } from "@mui/material";
import Sidebar from "./sidebar";
import Uploader from "../components/Uploader";
import { useDispatch, useSelector } from "react-redux";
import { setIsDragOver } from "../store/info/infoSlice";
import { useEffect, useState } from "react";
import axios from "../configs/axios";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
const Layout = ({
  className = null,
  header = null,
  sidebarContent = false,
  noUploader = false,
  children,
  bgImg
}) => {
  const dispatch = useDispatch();
  const sidebarOpen = useSelector((state) => state.sidebar.class);
  const isDragOver = useSelector((state) => state.info.isDragOver);
  const [messages, setMessages] = useState([]);

  async function getInfoMessages() {
    const response = await axios.get(process.env.REACT_APP_CMS_API_URL + '/announcements')
    let stringData = await localStorage.getItem('messages') || '[]'
    let infoMessages = JSON?.parse(stringData)
    response?.data?.forEach(element => {


      if (infoMessages?.includes(element?.id)) {
        return
      } else {
        infoMessages.push(element?.id)
        localStorage.setItem('messages', JSON.stringify(infoMessages))

        let tempMessages = messages;

        tempMessages.push(element)

        setMessages([...tempMessages])
      }
    });



  }
  function handleClose() {
    setMessages([])
  }
  useEffect(() => {
    getInfoMessages()
    //eslint-disable-next-line
  }, [])

  return (
    <div className={className + " " + sidebarOpen}>
      <Dialog
        open={messages?.length > 0}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Bilgilendirme"}
        </DialogTitle>
        <DialogContent>
          <div className="min-w-[500px]">
            {messages?.map(el => (
              <>
                {el.type === 'image' && <div className="mb-10" ><a href={el?.link}>
                  {el?.image && <img alt="" className="w-full" src={el?.image}></img>}
                  <DialogContentText id="alert-dialog-description">
                    {el?.title}
                  </DialogContentText>
                </a></div>
                }
                {el?.type === 'blog' && <div className="mb-10" ><a href={el?.link}>
                  <span dangerouslySetInnerHTML={{ __html: el?.description }} />

                </a>
                </div>}
              </>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Kapat
          </Button>
        </DialogActions>
      </Dialog>


      {bgImg && <div className="h-full w-1/5 bg-[rgba(13,28,48,0.8)] bg-left-area"></div>}

      <Box className={!isDragOver ? "absolute top-0 left-0 w-full" : "absolute top-0 left-0 w-full backdrop-blur-sm bg-[rgba(51,150,255,0.3)]"} sx={{ display: "flex" }}>
        <Box flexGrow={1}>
          {header && (
            <Grid container>
              <Grid xs={12} item>
                {header}
              </Grid>
            </Grid>
          )}
          <main style={{ height: "calc(100vh - 91px)", overflow: "auto" }}>
            {!noUploader && (
              <div
                className={isDragOver ? "upload drag" : "upload"}
                onDragEnter={(e) => {
                  console.log('1')

                  e.preventDefault();
                  dispatch(setIsDragOver(true));
                }}
                onDragOver={(e) => {
                  console.log('2')

                  e.preventDefault();
                  dispatch(setIsDragOver(true));
                }}
                onDragLeave={(e) => {
                  console.log('3')

                  e.preventDefault();
                  dispatch(setIsDragOver(false));
                }}
                onDrop={(e) => {
                  console.log('4')
                  e.preventDefault();
                  dispatch(setIsDragOver(false));
                }}
              >
                <Uploader />
              </div>
            )}
            <Container fixed={!sidebarContent}>
              {sidebarContent ? <Sidebar>{children}</Sidebar> : children}
            </Container>
          </main>
        </Box>
      </Box>
    </div>
  );
};

export default Layout;
