import Grid from "@mui/material/Grid";
import InfoMessage from "../../components/InfoMessage";
import Header from "./Header";

const Sidebar = ({ children }) => {
  return (
    <div className={"sidebar"}>
      <Grid container>
        <Grid xs={12} item>
          <Header />
        </Grid>
      </Grid>
      <div
        className="p-10 overflow-y-auto"
        style={{ height: "calc(100% - 90px)" }}
      >
        {children}
        <div className="absolute w-full px-10 inset-x-0 bottom-0">
          <InfoMessage />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
