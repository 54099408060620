import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../configs/axios";
import { debug } from "../../utils";
import { resetContactState } from "../contact/contactSlice";
import {
  resetLoading,
  setError,
  setInfo,
  setLoading,
  setSuccess,
} from "../info/infoSlice";
import { sidebarClose } from "../sidebar/sidebarSlice";
import { resetTransferState } from "../transfer/transferSlice";
import { setAction, setStep } from "../uploader/uploaderSlice";

export const login = createAsyncThunk(
  "auth/login",
  async (obj, { dispatch }) => {
    dispatch(setLoading("login"));
    const { navigate, ...body } = obj;
    const response = await axios.post("/login", body);
    debug("login", response);
    dispatch(resetLoading("login"));
    if (response.status === 201) {
      response.data?.access_token && navigate("/");
      dispatch(setAction("new"));
      dispatch(resetTransferState());
      dispatch(resetContactState());
      return response.data;
    }
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async (obj, { dispatch }) => {
    dispatch(setLoading("register"));
    const response = await axios.post("/register", obj);
    debug("register", response);
    dispatch(resetLoading("register"));
    if (response.status === 201) {
      return response.data;
    }
  }
);

export const edit = createAsyncThunk("auth/edit", async (obj, { dispatch }) => {
  dispatch(setLoading("edit"));
  const { id, ...data } = obj;
  const response = await axios.put(`/users/${id}`, data);
  dispatch(resetLoading("edit"));
  debug("editAuth", response);
  if (response.status === 200) {
    dispatch(
      setSuccess({
        message: "Your user information updated",
      })
    );
    return response.data;
  }
});

export const resetPassword = createAsyncThunk(
  "auth/reset-password",
  async (obj, { dispatch }) => {
    dispatch(setLoading("resetPassword"));
    const response = await axios.put("/reset-password", obj);
    debug("resetPassword", response);
    dispatch(resetLoading("resetPassword"));
    if (response.status === 200) {
      dispatch(
        setInfo({
          message: "Your password has been changed",
        })
      );
      dispatch(resetSession());
      return response.data;
    }
  }
);

export const forgetPassword = createAsyncThunk(
  "auth/forget-password",
  async (obj, { dispatch }) => {
    const { navigate, ...data } = obj;
    dispatch(setLoading("forgetPassword"));
    const response = await axios.post("/forget-password", data);
    debug("forgetPassword", response);
    dispatch(resetLoading("forgetPassword"));
    if (response.status === 406) {
      dispatch(setError(response.data));
    }
    if (response.status === 201) {
      navigate('/login?m=sreset')
      return response.data;
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (id = 0, { dispatch }) => {
    dispatch(setLoading("logout"));
    const response = await axios.delete("/logout");
    debug("logout", response);
    dispatch(sidebarClose());
    dispatch(resetLoading("logout"));
    if (response.status === 204) {
      return response.data;
    }
  }
);

export const checkAuth = createAsyncThunk(
  "auth/check-auth",
  async (token = "", { dispatch }) => {
    dispatch(setLoading("checkAuth"));
    if (!!token) {
      localStorage.removeItem("authUser");
      localStorage.setItem("accessToken", JSON.stringify(token));
    }
    dispatch(resetLoading("checkAuth"));
    const response = await axios.get(`/check-auth`);
    debug("checkAuth", response);
    if (response.status === 200) {
      return response.data;
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "auth/verify",
  async (token = "", { dispatch }) => {
    dispatch(setLoading("verifyEmail"));
    const response = await axios.post(`/verify`, { token });
    debug("verifyEmail", response);
    if (response.status === 200) {
      dispatch(resetLoading("verifyEmail"));
      return response.data;
    } else {
      dispatch(setStep("error"));
    }
  }
);

const initialState = {
  accessToken: "",
  isLoggedIn: false,
  registered: "",
  user: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetSession: (state, action) => {
      if (!!action.payload) {
        state[action.payload] = initialState[action.payload];
      } else {
        state.accessToken = initialState.accessToken;
        state.isLoggedIn = initialState.isLoggedIn;
        state.registered = initialState.registered;
        state.user = initialState.user;
        localStorage.removeItem("authUser");
        localStorage.removeItem("accessToken");
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload?.access_token) {
          const { access_token, user, ...other } = action.payload;
          localStorage.setItem("accessToken", JSON.stringify(access_token));
          state.accessToken = access_token;
          localStorage.setItem("authUser", JSON.stringify(user));
          localStorage.setItem("other", JSON.stringify(other));
          state.user = user;
          state.isLoggedIn = true;
        } else {
          localStorage.clear();
          state.isLoggedIn = false;
        }
      })
      .addCase(register.fulfilled, (state, action) => {
        state.registered = action.payload?.email;
      })
      .addCase(edit.fulfilled, (state, action) => {
        if (action.payload?.id) {
          localStorage.setItem("authUser", JSON.stringify(action.payload));
          state.user = action.payload;
        }
      })
      .addCase(checkAuth.fulfilled, (state, action) => {
        if (action.payload?.access_token) {
          const { access_token, user, ...other } = action.payload;
          localStorage.setItem("accessToken", JSON.stringify(access_token));
          state.accessToken = access_token;
          localStorage.setItem("authUser", JSON.stringify(user));
          localStorage.setItem("other", JSON.stringify(other));
          state.user = user;
          state.isLoggedIn = true;
        } else {
          localStorage.clear();
          state.isLoggedIn = false;
        }
      })
      .addCase(logout.fulfilled, (state, action) => {
        localStorage.removeItem("authUser");
        state.isLoggedIn = false;
        state.registered = "";
        state.accessToken = "";
        state.user = {};
        localStorage.clear();
      });
  },
});

export const { resetSession } = authSlice.actions;
export default authSlice.reducer;
